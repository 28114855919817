.Delete_Cliente_Content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  width: 300px;
}

.Delete_Cliente_Text {
  width: 100%;
  height: 36px;
  text-align: center;
  color: black;
  font-size: 16px;
  margin: 10px 0;
}

.Delete_Cliente_Icon {
  display: flex;
  margin: auto;
  margin-bottom: 10px;
}

.Delete_Cliente_Btns {
  display: flex;
  gap: 10px;
  justify-content: space-around;
  margin-top: 20px;
}

.Delete_Cliente_Cancel_Btn,
.Delete_Cliente_Delete_Btn {
  border-radius: 4px;
  border: none;
  color: white;
  padding: 10px 15px;
  cursor: pointer;
}

.Delete_Cliente_Cancel_Btn {
  background-color: #cccccc;
}

.Delete_Cliente_Delete_Btn {
  background-color: #fc4f44;
}

.Delete_Cliente_Delete_Btn:hover {
  background-color: #d43f3a;
}