.Update_Pagamento_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
}

.Update_Pagamento_itemField {
  padding: 10px 0px;
  width: 100%;
}

.Update_Pagamento_itemFieldLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.Update_Pagamento_itemField input {
  border: 1px solid #55b962;
  border-radius: 4px;
  background-color: rgba(242, 242, 242, 0.4);
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.Update_Pagamento_itemField input.Update_Pagamento_inputError {
  border: 1px solid #e46772;
}

.Update_Pagamento_itemFieldError {
  color: #e46772;
  font-size: 14px;
  padding: 2px 0;
}

.Update_Pagamento_saveBtn,
.Update_Pagamento_deleteBtn {
  width: 100%;
  margin-top: 20px;
  color: #fff;
  border-radius: 8px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Update_Pagamento_saveBtn {
  background-color: #0074db;
}

.Update_Pagamento_saveBtn:hover {
  background-color: #0066c9;
}

.Update_Pagamento_deleteBtn {
  background-color: #db0000;
}

.Update_Pagamento_deleteBtn:hover {
  background-color: #c90000;
}

.EditPagamentos_header_back {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-decoration: underline;
  color: #1f384c;
  border: none;
}

.EditPagamentos_header_HelpPage {
  border: none;
}

.Edit_Pagamento_storeLabel {
  display: flex;
  align-items: center;
}

.Edit_Pagamento_Icon {
  width: 23px;
  height: 23px;
  margin-left: 7px;
}

/* Responsividade para telas menores */
@media only screen and (max-width: 600px) {
  .Update_Pagamento_content {
    padding: 20px;
    max-width: 100%;
  }

  .Update_Pagamento_saveBtn,
  .Update_Pagamento_deleteBtn {
    height: 45px;
  }

  .Update_Pagamento_itemField input {
    padding: 12px;
  }

  .EditPagamentos_header_back {
    font-size: 16px;
  }
}