.Relatorio_staBlockTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  padding: 10px 15px;
}

.Relatorio_Dashboard_col_tableLinkA {
  color: #000000;
}

.Relatorio_Dashboard_col_tableLink {
  position: absolute;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #878997;
}

.Relatorio_Dashboard_col_tableLink > div {
  padding: 0 5px;
}

.Relatorio_right_side {
  /* Adiciona qualquer estilo adicional aqui se necessário */
}

.Relatorio {
  background: #fff;
  margin: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  transition: transform 100ms ease-in-out;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Permite que o conteúdo seja flexível em telas menores */
}

.Relatorio:hover {
  transform: scale(1.05);
}

.Relatorio_left_side {
  width: 100%; /* Ajusta para 100% para telas menores */
}

.Relatorio_os_icon {
  width: 120px;
}

.Relatorio_money_icon {
  width: 97px;
}

.Relatorio_title {
  font-size: 32px;
  font-weight: 800;
}

.Relatorio_title_row {
  display: flex;
  flex-wrap: wrap; /* Permite quebra em telas menores */
  align-items: center;
  gap: 10px; /* Espaçamento entre itens */
}

.Relatorio_value_row {
  margin-top: 40px;
}

.Relatorio_inner_rows {
  margin: 20px;
}

.Relatorio_title_col {
  display: flex;
  align-items: center;
}

.Relatorio_title_col_title {
  margin-right: 5px;
}

.Relatorio_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Flexível para telas pequenas */
}

/* Estilos para telas grandes (mínimo 800px) */
@media only screen and (min-width: 800px) {
  .Relatorio {
    display: flex;
    flex-direction: row; /* Exibe em linha para telas grandes */
  }
  
  .Relatorio_left_side {
    width: 50%; /* Define a largura da parte esquerda */
  }

  .Relatorio_right_side {
    width: 50%; /* Define a largura da parte direita */
  }
}

/* Estilos para telas menores (máximo 800px) */
@media only screen and (max-width: 800px) {
  .Relatorio_Dashboard_col_table {
    display: none !important;
  }
  
  .Relatorio_info {
    display: block !important;
  }
  
  .Relatorio {
    flex-direction: column; /* Coloca o conteúdo em uma coluna */
    padding: 15px;
  }
  
  .Relatorio_left_side,
  .Relatorio_right_side {
    width: 100%; /* Ajusta ambas as seções para ocupar 100% da largura */
  }
  
  .Relatorio_title {
    font-size: 24px; /* Reduz o tamanho da fonte para telas pequenas */
  }
  
  .Relatorio_value_row {
    margin-top: 20px;
  }

  .Relatorio_staBlockTitle {
    font-size: 16px; /* Ajusta o tamanho da fonte */
  }
}

/* Estilos para telas muito pequenas (máximo 480px) */
@media only screen and (max-width: 480px) {
  .Relatorio {
    margin: 10px;
    padding: 10px;
  }
  
  .Relatorio_title {
    font-size: 20px;
  }
  
  .Relatorio_staBlockTitle {
    font-size: 14px;
  }

  .Relatorio_value_row {
    margin-top: 10px;
  }
}