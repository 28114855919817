.AddCliente_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.AddCliente_header {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
}

.AddCliente_header_title {
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
}

.AddCliente_header_back {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-decoration: underline;
  color: #1f384c;
  cursor: pointer;
}

.AddCliente_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
}

.AddCliente_itemField {
  padding: 10px 0px;
  width: 100%;
}

.AddCliente_itemFieldLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.AddCliente_itemField input {
  border: 1px solid #55b962;
  border-radius: 4px;
  background-color: rgba(242, 242, 242, 0.4);
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.ant-picker {
  width: 100%;
}

.AddCliente_itemField input.AddCliente_inputError {
  border: 1px solid #e46772;
}

.AddCliente_saveBtn {
  margin-top: 20px;
  background-color: #0c8ce9;
  color: #fff;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  border: none;
  cursor: pointer;
}

.AddCliente_saveBtn:hover {
  background-color: #0a73c4;
}

.AddCliente_itemFieldError {
  color: #e46772;
  font-size: 14px;
  padding: 2px 0;
}

.AddCliente_Label_Icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.AddCliente_Icon {
  width: 22px;
  margin-left: 10px;
  margin-bottom: 0.5rem;
}

.AddCliente_Reload_Icon {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .AddCliente_content {
      padding: 15px;
  }

  .AddCliente_header_title {
      font-size: 20px;
  }

  .AddCliente_header_back {
      font-size: 16px;
  }

  .AddCliente_saveBtn {
      height: 35px;
  }

  .AddCliente_itemFieldLabel {
      font-size: 12px;
  }

  .AddCliente_itemField input {
      padding: 8px;
  }
}