.NotFoundWrapper {
    background: #1E1E2E;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
}

.NotFoundText {
    color: #fff;
    font-size: 2rem;
    text-align: center;
}

.NotFoundGoHome {
    color: #fff;
    font-size: 1rem;
    margin-top: 20px;
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .NotFoundText {
        font-size: 1.5rem;
    }

    .NotFoundGoHome {
        font-size: 0.875rem;
    }
}