.AddMachine_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.AddMachine_header {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
}

.AddMachine_header_title {
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
}

.AddMachine_header_back {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-decoration: underline;
    color: #1F384C;
    cursor: pointer;
}

.AddMachine_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
}

.AddMachine_itemField {
    padding: 10px 0px;
    width: 100%;
}

.AddMachine_itemFieldLabel {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.AddMachine_itemField input {
    border: 1px solid #55B962;
    border-radius: 4px;
    background-color: rgba(242, 242, 242, 0.4);
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.AddMachine_itemField input.AddMachine_inputError {
    border: 1px solid #E46772;
}

.AddMachine_saveBtn {
    margin-top: 20px;
    background-color: #0C8CE9;
    color: #fff;
    border-radius: 4px;
    height: 40px;
    width: 100%;
    border: none;
    cursor: pointer;
}

.AddMachine_saveBtn:hover {
    background-color: #0A73C4;
}

.AddMachine_itemFieldError {
    color: #E46772;
    font-size: 14px;
    padding: 2px 0;
}

/* Media Queries para responsividade */
@media only screen and (max-width: 600px) {
    .AddMachine_content {
        padding: 15px;
    }

    .AddMachine_header_title {
        font-size: 20px; 
    }

    .AddMachine_header_back {
        font-size: 16px;
    }

    .AddMachine_saveBtn {
        height: 35px;
    }

    .AddMachine_itemFieldLabel {
        font-size: 12px;
    }

    .AddMachine_itemField input {
        padding: 8px;
    }
}