.Cliente_Maquinas_col {
  padding: 20px;
}

.Cliente_Maquinas_staBlock {
  background: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.Cliente_Maquinas_staBlock:hover {
  transform: translateY(-5px);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
}

.Cliente_Maquinas_staBlockLinkA {
  color: #000000;
}

.Cliente_Maquinas_header {
  display: flex;
  align-items: center;
}

.Cliente_Maquinas_action {
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
  scroll-snap-type: x mandatory;
}

.Cliente_Maquinas_action button {
  flex: none;
  scroll-snap-align: start;
}

.Cliente_Maquinas_action_buttons {
  display: inline-flex;
}

.Cliente_Maquinas_staBlockLink {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
}

.Cliente_Maquinas_staBlockLink > div {
  padding: 0 5px;
}

.Cliente_Maquinas_staBlockTitle {
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  padding: 10px 15px;
  color: #333;
}

.Cliente_Maquinas_addbtn {
  display: inline-flex !important;
  align-items: center;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.Cliente_Maquinas_addbtn:hover {
  background-color: #45a049;
}

.Cliente_Maquinas_staBlockAmount {
  flex: 1;
  font-weight: 700;
  font-size: 36px;
  line-height: 66px;
  color: #000000;
  padding: 0 10px;
}

.Cliente_Maquinas_staBlockBottom img {
  width: 100%;
}

.Cliente_Maquinas_table {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 9px;
  padding: 20px;
  height: 100%;
  position: relative;
  border-collapse: collapse;
}

.Cliente_Maquinas_table th,
.Cliente_Maquinas_table td {
  border: 1px solid #e0e0e0;
  padding: 12px;
  text-align: left;
}

.Cliente_Maquinas_table th {
  background-color: #f5f5f5;
  font-weight: 700;
}

.Cliente_Maquinas_table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.Cliente_Maquinas_table tr:hover {
  background-color: #f1f1f1;
}

.Cliente_Maquinas_table th.ant-table-cell {
  font-weight: 700;
}

@media (max-width: 768px) {
  .Cliente_Maquinas_action_buttons {
    flex-wrap: nowrap;
  }

  .Cliente_Maquinas_addbtn {
    min-width: 120px;
  }

  .Cliente_Maquinas_action_buttons {
    display: block;
  }
}