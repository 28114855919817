.Admin_SignIn_textImage1 {
  position: absolute;
  top: 5%;
  left: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
}

.Admin_SignIn_textTitle {
  font-weight: 700;
  font-size: 8vw;
  text-align: left;
}

.Admin_SignIn_textDescription {
  font-weight: 700;
  font-size: 5vw;
  line-height: 1.5;
  text-align: left;
  max-width: 90%;
}

@media (min-width: 768px) {
  .Admin_SignIn_textImage1 {
    top: 20px;
    left: 20px;
  }

  .Admin_SignIn_textTitle {
    font-size: 40px;
  }

  .Admin_SignIn_textDescription {
    font-size: 24px;
    max-width: 500px;
  }
}